/* eslint-disable @typescript-eslint/no-redeclare */

export const GET_USER = "GET_USER";
export type GET_USER = typeof GET_USER;

export const LOAD_USER = "LOAD_USER";
export type LOAD_USER = typeof LOAD_USER;

export const USER_LOADED = "USER_LOADED";
export type USER_LOADED = typeof USER_LOADED;

export const CLAIM_ACCOUNT = "CLAIM_ACCOUNT";
export type CLAIM_ACCOUNT = typeof CLAIM_ACCOUNT;

export const ACCOUNT_CLAIMED = "ACCOUNT_CLAIMED";
export type ACCOUNT_CLAIMED = typeof ACCOUNT_CLAIMED;

export const USERS_LOADED = "USERS_LOADED";
export type USERS_LOADED = typeof USERS_LOADED;

export const USER_ASSIGNED = "USER_ASSIGNED";
export type USER_ASSIGNED = typeof USER_ASSIGNED;

export const USER_UNASSIGNED = "USER_UNASSIGNED";
export type USER_UNASSIGNED = typeof USER_UNASSIGNED;

export const UPDATE_USER = "UPDATE_USER";
export type UPDATE_USER = typeof UPDATE_USER;

export const FETCH_USERS = "FETCH_USERS";
export type FETCH_USERS = typeof FETCH_USERS;

export const USER_BROADCAST = "USER_BROADCAST";
export type USER_BROADCAST = typeof USER_BROADCAST;

export const USER_UPDATED = "USER_UPDATED";
export type USER_UPDATED = "USER_UPDATED";

export const USER_CONNECTED = "USER_CONNECTED";
export type USER_CONNECTED = typeof USER_CONNECTED;

export const USER_STATUS_UPDATED = "USER_STATUS_UPDATED";
export type USER_STATUS_UPDATED = typeof USER_STATUS_UPDATED;

export const SET_USER_PRESENCE = "SET_USER_PRESENCE";
export type SET_USER_PRESENCE = typeof SET_USER_PRESENCE;

export const SET_USER_STATUS = "SET_USER_STATUS";
export type SET_USER_STATUS = typeof SET_USER_STATUS;

export const PRESENCE_LOADED = "PRESENCE_LOADED";
export type PRESENCE_LOADED = typeof PRESENCE_LOADED;

export const PRESENCE_LOST = "PRESENCE_LOST";
export type PRESENCE_LOST = typeof PRESENCE_LOST;

export const PRESENCE_UPDATED = "PRESENCE_UPDATED";
export type PRESENCE_UPDATED = typeof PRESENCE_UPDATED;

export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
export type UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";

export const UPDATE_USER_PASSWORD = "UPDATE_USER_PASSWORD";
export type UPDATE_USER_PASSWORD = "UPDATE_USER_PASSWORD";

// PREFERENCE
export const SET_PREFERENCE = "UPDATE_PREFERENCES";
export type SET_PREFERENCE = typeof SET_PREFERENCE;

export const PREFERENCE_SET = "PREFERENCES_UPDATED";
export type PREFERENCE_SET = typeof PREFERENCE_SET;

export const PREFERENCES_LOADED = "PREFERENCES_LOADED";
export type PREFERENCES_LOADED = typeof PREFERENCES_LOADED;
