/* eslint-disable @typescript-eslint/no-redeclare */

export const CREATE_THREAD = "CREATE_THREAD";
export type CREATE_THREAD = typeof CREATE_THREAD;

export const RENAME_THREAD = "RENAME_THREAD";
export type RENAME_THREAD = typeof RENAME_THREAD;

export const SEARCH_THREAD = "SEARCH_THREAD";
export type SEARCH_THREAD = typeof SEARCH_THREAD;

export const THREAD_SEARCH_RESULT = "THREAD_SEARCH_RESULT";
export type THREAD_SEARCH_RESULT = typeof THREAD_SEARCH_RESULT;

export const ARCHIVE_THREAD = "ARCHIVE_THREAD";
export type ARCHIVE_THREAD = typeof ARCHIVE_THREAD;

export const DELETE_THREAD = "DELETE_THREAD";
export type DELETE_THREAD = typeof DELETE_THREAD;

export const UNARCHIVE_THREAD = "UNARCHIVE_THREAD";
export type UNARCHIVE_THREAD = typeof UNARCHIVE_THREAD;

export const THREAD_ARCHIVED = "THREAD_ARCHIVED";
export type THREAD_ARCHIVED = typeof THREAD_ARCHIVED;

export const THREAD_UNARCHIVED = "THREAD_UNARCHIVED";
export type THREAD_UNARCHIVED = typeof THREAD_UNARCHIVED;

export const GET_MESSAGE = "GET_MESSAGE";
export type GET_MESSAGE = typeof GET_MESSAGE;

export const THREAD_DRAFT_UPDATED = "THREAD_DRAFT_UPDATED";
export type THREAD_DRAFT_UPDATED = typeof THREAD_DRAFT_UPDATED;

export const THREAD_DRAFT_REPLY_SET = "THREAD_DRAFT_REPLY_SET";
export type THREAD_DRAFT_REPLY_SET = typeof THREAD_DRAFT_REPLY_SET;

export const MESSAGE_LOADED = "MESSAGE_LOADED";
export type MESSAGE_LOADED = typeof MESSAGE_LOADED;

export const MESSAGES_LOADED = "MESSAGES_LOADED";
export type MESSAGES_LOADED = typeof MESSAGES_LOADED;

export const NEW_MESSAGE = "NEW_MESSAGE";
export type NEW_MESSAGE = typeof NEW_MESSAGE;

export const DELETE_MESSAGE = "DELETE_MESSAGE";
export type DELETE_MESSAGE = typeof DELETE_MESSAGE;

export const UPDATE_MESSAGE = "UPDATE_MESSAGE";
export type UPDATE_MESSAGE = typeof UPDATE_MESSAGE;

export const FETCH_MESSAGES = "FETCH_MESSAGES";
export type FETCH_MESSAGES = typeof FETCH_MESSAGES;

export const MESSAGE_CREATED = "MESSAGE_CREATED";
export type MESSAGE_CREATED = typeof MESSAGE_CREATED;

export const MESSAGE_UPDATED = "MESSAGE_UPDATED";
export type MESSAGE_UPDATED = typeof MESSAGE_UPDATED;

export const MESSAGE_DELETED = "MESSAGE_DELETED";
export type MESSAGE_DELETED = typeof MESSAGE_DELETED;

export const POST_MESSAGE = "POST_MESSAGE";
export type POST_MESSAGE = typeof POST_MESSAGE;

export const POST_DIRECT_MESSAGE = "POST_DIRECT_MESSAGE";
export type POST_DIRECT_MESSAGE = typeof POST_DIRECT_MESSAGE;

export const PIN_MESSAGE = "PIN_MESSAGE";
export type PIN_MESSAGE = typeof PIN_MESSAGE;

export const UNPIN_MESSAGE = "UNPIN_MESSAGE";
export type UNPIN_MESSAGE = typeof UNPIN_MESSAGE;

export const REACT_MESSAGE = "REACT_MESSAGE";
export type REACT_MESSAGE = typeof REACT_MESSAGE;

export const UNREACT_MESSAGE = "UNREACT_MESSAGE";
export type UNREACT_MESSAGE = typeof UNREACT_MESSAGE;

export const USER_REACTED = "USER_REACTED";
export type USER_REACTED = typeof USER_REACTED;

export const USER_UNREACTED = "USER_UNREACTED";
export type USER_UNREACTED = typeof USER_UNREACTED;

export const REACTION_LOADED = "REACTION_LOADED";
export type REACTION_LOADED = typeof REACTION_LOADED;

export const REACTION_CREATED = "REACTION_CREATED";
export type REACTION_CREATED = typeof REACTION_CREATED;

export const REACTION_DELETED = "REACTION_DELETED";
export type REACTION_DELETED = typeof REACTION_DELETED;

// Thread actions
export const GET_THREAD = "GET_THREAD";
export type GET_THREAD = typeof GET_THREAD;

export const THREAD_LOADED = "THREAD_LOADED";
export type THREAD_LOADED = typeof THREAD_LOADED;

export const THREAD_ACTIVITY = "THREAD_ACTIVITY";
export type THREAD_ACTIVITY = typeof THREAD_ACTIVITY;

export const THREAD_CREATED = "THREAD_CREATED";
export type THREAD_CREATED = typeof THREAD_CREATED;

export const THREAD_UPDATED = "THREAD_UPDATED";
export type THREAD_UPDATED = typeof THREAD_UPDATED;

export const THREAD_DELETED = "THREAD_DELETED";
export type THREAD_DELETED = typeof THREAD_DELETED;

export const LOAD_THREAD = "LOAD_THREAD";
export type LOAD_THREAD = typeof LOAD_THREAD;

export const THREAD_CONNECTED = "THREAD_CONNECTED";
export type THREAD_CONNECTED = typeof THREAD_CONNECTED;

export const DEACTIVATE_THREAD = "DEACTIVATE_THREAD";
export type DEACTIVATE_THREAD = typeof DEACTIVATE_THREAD;

export const THREAD_PAGE_UPDATED = "THREAD_PAGE_UPDATED";
export type THREAD_PAGE_UPDATED = typeof THREAD_PAGE_UPDATED;

export const INIT_CONVERSATION = "INIT_CONVERSATION";
export type INIT_CONVERSATION = typeof INIT_CONVERSATION;

export const CREATE_CONVERSATION = "CREATE_CONVERSATION";
export type CREATE_CONVERSATION = typeof CREATE_CONVERSATION;

export const TRIM_CONVSERSATION = "TRIM_CONVSERSATION";
export type TRIM_CONVSERSATION = typeof TRIM_CONVSERSATION;

export const LOAD_CONVERSATION = "LOAD_CONVERSATION";
export type LOAD_CONVERSATION = typeof LOAD_CONVERSATION;

export const SET_CONVERSATION_PAGE = "SET_CONVERSATION_PAGE";
export type SET_CONVERSATION_PAGE = typeof SET_CONVERSATION_PAGE;

export const LOADING_CONVERSATION = "LOADING_CONVERSATION";
export type LOADING_CONVERSATION = typeof LOADING_CONVERSATION;

export const CONVERSATION_LOADED = "CONVERSATION_LOADED";
export type CONVERSATION_LOADED = typeof CONVERSATION_LOADED;

export const CONCAT_CONVERSATION = "CONCAT_CONVERSATION";
export type CONCAT_CONVERSATION = typeof CONCAT_CONVERSATION;

export const THREAD_SEARCH_FILTER_UPDATED = "THREAD_SEARCH_FILTER_UPDATED";
export type THREAD_SEARCH_FILTER_UPDATED = typeof THREAD_SEARCH_FILTER_UPDATED;
