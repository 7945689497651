import React from "react";
import { createPortal } from "react-dom";
import { useTransition, animated } from "@react-spring/web";

const disposable = 'x-ui-drawer-void';


export interface IDrawer extends React.HTMLAttributes<HTMLDivElement> {
    /**
     * State of the drawer
     * @default false
     * @type boolean
     */
    open: boolean;

    /**
     * The ID of the element that will be used as the root container for the drawer.
     * @default document.body
     * @type string | HTMLElement
     */
    root?: HTMLElement | string;

    /**
     * The position of the drawer
     * @default left
     * @type "left" | "right" | "top" | "bottom"
     */
    position?: "right" | "left" | "top" | "bottom";

    /**
     * The duration of the drawer animation
     * @default 150
     * @type number
     */
    duration?: number;

    /**
     * Callback fired when the component requests to be closed.
     * Signature:   function(reason: string, event: object) => void
     * reason:      Can be:`"backdropClicked"` or `"escapeKeyDown @todo"`
     */
    onClose?: (reason: string, el: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export interface IBackdrop {
    /**
     * The content of the component.
     */
    children?: React.ReactNode | React.ReactNodeArray | null | Array<React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | null | undefined>;

    /**
     * Callback fired when the component requests to be closed.
     * Signature:   function(reason: string, event: object) => void
     * reason:      Can be:`"backdropClicked"` or `"escapeKeyDown @todo"`
     */
    onClick?: (reason: string, el: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const positions = {
    top: {
        enter: { transform: "translateY(0%)" },
        from: { transform: "translateY(-100%)" },
        leave: { transform: "translateY(-100%)" },
    },
    left: {
        enter: { transform: "translateX(0%)" },
        from: { transform: "translateX(-100%)" },
        leave: { transform: "translateX(-100%)" },
    },
    right: {
        enter: { transform: "translateX(0%)" },
        from: { transform: "translateX(100%)" },
        leave: { transform: "translateX(100%)" },
    },
    bottom: {
        enter: { transform: "translateY(0%)" },
        from: { transform: "translateY(100%)" },
        leave: { transform: "translateY(100%)" },
    },
}

// This is a helper function to get the root element for the component
function getRoot(root: HTMLElement | string): HTMLElement {
    if (typeof root === "string") {
        const node = document.getElementById(root);
        if(node){
            return node;
        }
        return document.createElement(disposable);
    }
    return root;
}

const Backdrop = React.forwardRef<HTMLDivElement, IBackdrop>(({ children, onClick}, ref)=> {
    const backdropRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        if(ref){
            if(ref instanceof Function){
                ref(backdropRef.current);
            } else if(ref instanceof Object){
                ref.current = backdropRef.current;
            }
        }
    },[backdropRef.current, ref]);

    function handleBackdropClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        if(onClick){
            event.stopPropagation();
            event.preventDefault();
            onClick!("backdropClicked", event)
        }
    }


    return (
        <div ref={backdropRef} onClick={handleBackdropClick} className="drawer-ui-backdrop absolute w-full h-full top-0 left-0 backdrop-blur-md">
            <div className="w-full h-full bg-gray-900 opacity-50"/>
        </div>
    );
});

export default React.forwardRef<HTMLDivElement, IDrawer>( ({ open, root, duration=150, onClose, position="left", ...props }, ref)  => {
    const rootEl = getRoot(root ?? document.body);


    const config = { duration };

    const transition = useTransition(open, {...positions[position], config, keys: null });

    // Cleanup the root element if it is disposable
    React.useEffect(() => {
        return () => {
            if (rootEl && rootEl.tagName === disposable.toUpperCase()) {
                rootEl.remove();
            }
        }
    }, [rootEl]);


    return createPortal(
            transition((style, open) => {
                return  open && (
                <React.Fragment>
                    <Backdrop onClick={onClose}/>
                    <animated.div {...props} ref={ref} style={{...style, position: "absolute", [position]: 0}}>
                        {props.children}
                    </animated.div>
                </React.Fragment>
                )
            }), rootEl);

});
