/* eslint-disable @typescript-eslint/no-redeclare */

export const ROLE_UPDATED = "ROLE_UPDATED";
export type ROLE_UPDATED = typeof ROLE_UPDATED;

export const ROLE_DELETED = "ROLE_DELETED";
export type ROLE_DELETED = typeof ROLE_DELETED;

export const ROLE_LOADED = "ROLE_LOADED";
export type ROLE_LOADED = typeof ROLE_LOADED;

export const ROLES_LOADED = "ROLES_LOADED";
export type ROLES_LOADED = typeof ROLES_LOADED;

export const ROLE_ASSIGNED = "ROLE_ASSIGNED";
export type ROLE_ASSIGNED = typeof ROLE_ASSIGNED;

export const ROLE_UNASSIGNED = "ROLE_UNASSIGNED";
export type ROLE_UNASSIGNED = typeof ROLE_UNASSIGNED;

export const ROLE_PERMISSION_SET = "ROLE_PERISSION_SET";
export type ROLE_PERMISSION_SET = typeof ROLE_PERMISSION_SET;

export const GET_SPACE_ROLE = "GET_SPACE_ROLE";
export type GET_SPACE_ROLE = typeof GET_SPACE_ROLE;

export const SET_DEFAULT_SPACE_ROLE = "SET_DEFAULT_SPACE_ROLE";
export type SET_DEFAULT_SPACE_ROLE = typeof SET_DEFAULT_SPACE_ROLE;

export const FETCH_SPACE_ROLES = "FETCH_SPACE_ROLES";
export type FETCH_SPACE_ROLES = typeof FETCH_SPACE_ROLES;

export const LOAD_SPACE_ROLES = "LOAD_SPACE_ROLES";
export type LOAD_SPACE_ROLES = typeof LOAD_SPACE_ROLES;

export const DELETE_SPACE_ROLE = "DELETE_SPACE_ROLE";
export type DELETE_SPACE_ROLE = typeof DELETE_SPACE_ROLE;

export const SPACE_ROLE_LOADED = "SPACE_ROLE_LOADED";
export type SPACE_ROLE_LOADED = typeof SPACE_ROLE_LOADED;

export const SPACE_ROLES_LOADED = "SPACE_ROLES_LOADED";
export type SPACE_ROLES_LOADED = typeof SPACE_ROLES_LOADED;

export const CREATE_SPACE_ROLE = "CREATE_SPACE_ROLE";
export type CREATE_SPACE_ROLE = typeof CREATE_SPACE_ROLE;

export const SET_SPACE_PERMISSION = "SET_SPACE_PERMISSION";
export type SET_SPACE_PERMISSION = typeof SET_SPACE_PERMISSION;

export const UNSET_SPACE_PERMISSION = "DELETE_SPACE_PERMISSION";
export type UNSET_SPACE_PERMISSION = typeof UNSET_SPACE_PERMISSION;

export const SPACE_PERMISSION_UNSET = "SPACE_PERMISSION_DELETED";
export type SPACE_PERMISSION_UNSET = typeof SPACE_PERMISSION_UNSET;

export const SPACE_PERMISSION_SET = "SPACE_PERMISSION_SET";
export type SPACE_PERMISSION_SET = typeof SPACE_PERMISSION_SET;

export const SPACE_ROLE_CREATED = "SPACE_ROLE_CREATED";
export type SPACE_ROLE_CREATED = typeof SPACE_ROLE_CREATED;

export const SPACE_ROLE_UPDATED = "SPACE_ROLE_UPDATED";
export type SPACE_ROLE_UPDATED = typeof SPACE_ROLE_UPDATED;

export const SPACE_ROLE_DELETED = "SPACE_ROLE_DELETED";
export type SPACE_ROLE_DELETED = typeof SPACE_ROLE_DELETED;
